/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { map as o, result as l } from "./asyncUtils.js";
import n from "./Collection.js";
import r from "./Loadable.js";
async function t(o, l) {
  return await o.load(), a(o, l);
}
async function a(t, a) {
  const s = [],
    c = (...o) => {
      for (const l of o) null != l && (Array.isArray(l) ? c(...l) : n.isCollection(l) ? l.forEach(o => c(o)) : r.isLoadable(l) && s.push(l));
    };
  a(c);
  let f = null;
  if (await o(s, async o => {
    const n = await l(i(o) ? o.loadAll() : o.load());
    !1 !== n.ok || f || (f = n);
  }), f) throw f.error;
  return t;
}
function i(o) {
  return "loadAll" in o && "function" == typeof o.loadAll;
}
export { t as loadAll, a as loadAllChildren };